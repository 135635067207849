<template>
    <CRow>
        <CCol>

            <WidgetsDropdownDashboard1/>
            
        </CCol>
     </CRow>
</template>


<script>
import WidgetsDropdownDashboard1 from './widgets/WidgetsDropdownDashboard1';

export default {
  name: 'Dashboard1',
  components: { WidgetsDropdownDashboard1 },
}
</script>
